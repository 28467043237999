@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TLFont';
  src: url('../public/fonts/tl.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'TLFont', sans-serif;
}